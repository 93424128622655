import { graphql, useStaticQuery } from 'gatsby';

const usePosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allMyNodeType {
        nodes {
          email
          displayName
          id
          photoURL
        }
      }
    }
  `);

  return data.allMyNodeType.nodes;
};

export default usePosts;
